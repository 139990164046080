<template>
  <v-row dense v-if="allowed">
    <v-col cols="12">
      <label>Toggle Permission</label>
      <v-switch
        label="Allow Permission handling"
        dense
        aut-permission-toggle
        v-model="permissions.enabled"
        @change="$emit('update:permissions', permissions)"
        class="mt-0"
      ></v-switch>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "PermissionNode",
  props: {
    permissions: {
      type: Object,
      default: () => {
        return {
          enabled: false,
        };
      },
    },
  },
  computed: {
    allowed() {
      return this.isFeatureEnabled("permissions.non_editor.enabled");
    },
  },
};
</script>
